import React from "react"
import Header from "./Header"
import Head from "./Head"
import Footer from "./Footer"
/* eslint-disable jsx-a11y/anchor-is-valid */
// Temporary disable rule for a href links

export default ({ children }) => (
  <div>
    <Head />
    <Header />
    {children}
    <Footer />
  </div>
)
