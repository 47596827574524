import React from "react";
import { Helmet } from "react-helmet";

const Head = () => (
  <Helmet>
    <link
      href="https://fonts.googleapis.com/css?family=Fira+Sans:300,400,700&display=swap"
      rel="stylesheet"
    />
    <meta charSet="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>DataSonnet</title>
    <script defer src="https://use.fontawesome.com/releases/v5.0.7/js/all.js" />
  </Helmet>
)

export default Head;
