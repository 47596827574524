import React from "react"
import { repoAddress, docsAddress } from "../config/global"
/* eslint-disable jsx-a11y/anchor-is-valid */
// Temporary disable rule for a href links

const Footer = () => (
  <footer className="footer">
    <div className="p-0-50">
      {/*<div className="footer-icons">
        <figure className="image is-32x32">
          <img alt="" src="https://bulma.io/images/placeholders/128x128.png" />
        </figure>
        <figure className="image is-32x32">
          <img alt="" src="https://bulma.io/images/placeholders/128x128.png" />
        </figure>
        <figure className="image is-32x32">
          <img alt="" src="https://bulma.io/images/placeholders/128x128.png" />
        </figure>
        </div>*/}
      <p>
        <a href={repoAddress}>Git page</a>
      </p>
      <p>
        <a href={docsAddress}>Documentation</a>
      </p>
    </div>
  </footer>
)

export default Footer
