import React from "react"
import { Link } from "gatsby"
import { docsAddress, repoAddress } from "../config/global"
import Image from "./Image"
/* eslint-disable jsx-a11y/anchor-is-valid */
// Temporary disable rule for a href links

const Header = () => (
  <nav className="navbar" role="navigation" aria-label="dropdown navigation">
    <div className="navbar-brand">
      <Link to={`/`} className="navbar-item">
        <figure className="image is-32x32">
          <Image alt="DataSonnet" filename="datasonnet/LogoWhite.png" />
        </figure>
        <span className="logo-title is-size-4-desktop is-size-6-mobile m-l-10">
          DataSonnet
        </span>
      </Link>
    </div>
    <div className="navbar-menu is-active">
      <div className="navbar-end">
        <div className="navbar-item">
          <Link to={`/posts`} className="is-size-6-desktop is-size-7-mobile">
            Blog
          </Link>
        </div>
        <div className="navbar-item">
          <a className="is-size-6-desktop is-size-7-mobile" href={docsAddress}>
            Docs
          </a>
        </div>
        <div className="navbar-item">
          <a className="is-size-6-desktop is-size-7-mobile" href={repoAddress}>
            Git Repo
          </a>
        </div>
      </div>
    </div>
  </nav>
)

export default Header
